import { z } from 'zod';

import { StrictExclude } from '../utils/types/strict-exclude';

/** Quote job schema. */
export const quoteJobSchema = z.object({
	jobId: z.string(),
	id: z.number(),
	jobName: z.string(),
	jobAddress: z.string(),
	city: z.string().nullable(),
	state: z.string().nullable(),
	lastActivityDate: z.date(),
	shipmentsReceived: z.number(),
	itemsInStorage: z.number(),
	isCompleted: z.boolean(),
	completedOn: z.date().nullable(),
	companyName: z.string(),
	companyId: z.number(),
});

/** User job extra meta data schema. */
export const userJobExtraMetaDataSchema = z.object({
	companyJobs: z.number(),
	otherJobs: z.number(),
});

/** Quote job data. */
export type QuoteJob = Readonly<z.infer<typeof quoteJobSchema>>;

/** Job available sort fields. */
export type QuoteJobSortField = StrictExclude<keyof QuoteJob, 'completedOn' | 'id'>;

/** User job extra meta data. */
export type UserJobExtraMetaData = Readonly<z.infer<typeof userJobExtraMetaDataSchema>>;

import { Injectable, inject } from '@angular/core';

import { UserJobsFilterParams } from '../models/user-jobs-filter-params';
import { UserJobsFilterParamsDto } from '../dtos/user-jobs-filter-params.dto';

import { BaseFilterParamsMapper } from './base-filter-params.mapper';
import { MapperToDto } from './mappers';
import { QUOTE_JOBS_SORT_FIELD_MAP } from './quote-jobs-filter-params.mapper';

/** User jobs filter params mapper. */
@Injectable({
	providedIn: 'root',
})
export class UserJobsFilterParamsMapper implements MapperToDto<UserJobsFilterParamsDto, UserJobsFilterParams> {

	private readonly baseFilterParamsMapper = inject(BaseFilterParamsMapper);

	/** @inheritdoc */
	public toDto(data: UserJobsFilterParams): UserJobsFilterParamsDto {
		return {
			...this.baseFilterParamsMapper.mapCombinedOptionsToDto(data, QUOTE_JOBS_SORT_FIELD_MAP),
			ids: data.ids?.join(','),
			additional_ids: data.additionalIds?.join(','),
			exclude_ids: data.excludedIds?.join(','),
		};
	}
}

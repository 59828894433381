import { Injectable, inject } from '@angular/core';

import { QuoteJobsFilterParamsDto } from '../dtos/quote-jobs-filter-params.dto';
import { QuoteJobsFilterParams } from '../models/quote-jobs-filter-params';

import { QuoteJobSortFieldDto } from '../dtos/quote-job.dto';

import { QuoteJobSortField } from '../models/quote-job';

import { BaseFilterParamsMapper } from './base-filter-params.mapper';
import { MapperToDto } from './mappers';

/** Quote jobs sort field map. */
export const QUOTE_JOBS_SORT_FIELD_MAP: Readonly<Record<QuoteJobSortField, QuoteJobSortFieldDto>> = {
	jobId: 'job_id',
	jobAddress: 'job_address',
	isCompleted: 'is_completed',
	companyName: 'company_name',
	companyId: 'company_id',
	jobName: 'job_name',
	city: 'city',
	state: 'state',
	shipmentsReceived: 'shipments_received',
	lastActivityDate: 'last_activity_date',
	itemsInStorage: 'items_in_storage',
};

/** Quote jobs filter params mapper. */
@Injectable({
	providedIn: 'root',
})
export class QuoteJobsFilterParamsMapper implements MapperToDto<QuoteJobsFilterParamsDto, QuoteJobsFilterParams> {

	private readonly baseFilterParamsMapper = inject(BaseFilterParamsMapper);

	/** @inheritdoc */
	public toDto(data: QuoteJobsFilterParams): QuoteJobsFilterParamsDto {
		return {
			...this.baseFilterParamsMapper.mapCombinedOptionsToDto(data, QUOTE_JOBS_SORT_FIELD_MAP),
			is_completed: data.isCompleted ?? undefined,
			company_ids: data.includedCompanyIds?.join(',') ?? undefined,
			excluded_company_ids: data.excludedCompanyIds?.join(',') ?? undefined,
			ids: data.includedJobIds?.join(',') ?? undefined,
			excluded_ids: data.excludedJobIds?.join(',') ?? undefined,
			additional_job_ids: data.additionalJobIds?.join(',') ?? '1',
			company_and_additional_jobs: data.enableCompanyAndAdditionalJobs ?? undefined,
		};
	}
}

import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

import { createPaginationDtoSchema, createPaginationDtoWithExtraMetaDataSchema } from '../dtos/pagination.dto';
import { userDetailsSchemaDto } from '../dtos/user-details.dto';
import { userDtoSchema } from '../dtos/user.dto';
import { PaginationMapper } from '../mappers/pagination.mapper';
import { UserDetailsMapper } from '../mappers/user-details.mapper';
import { UserFilterParamsMapper } from '../mappers/user-filter-params.mapper';
import { UserStatusFilterParamsMapper } from '../mappers/user-status-filter-params.mapper';
import { UserMapper } from '../mappers/user.mapper';
import { Pagination } from '../models/pagination';
import { User, UserCreate } from '../models/user';
import { UserDetails } from '../models/user-details';
import { UserStatusFilterParams } from '../models/user-status-filter-params';
import { UsersFilterParams } from '../models/users-filter-params';
import { composeHttpParams } from '../utils/compose-http-params';
import { safeParse } from '../utils/safe-parse';
import { AppErrorMapper } from '../mappers/app-error.mapper';
import { UserJobsFilterParamsMapper } from '../mappers/user-jobs-filter-params.mapper';
import { UserJobsFilterParams } from '../models/user-jobs-filter-params';
import { QuoteJob, UserJobExtraMetaData } from '../models/quote-job';
import { quoteJobDtoSchema, userJobExtraMetaDataDtoSchema } from '../dtos/quote-job.dto';
import { QuoteJobMapper } from '../mappers/quote-job.mapper';
import { UserJobExtraMetaDataMapper } from '../mappers/user-job-extra-meta-data.mapper';

import { AppUrlsConfig } from './app-urls.config';

/** Performs CRUD operations for users. */
@Injectable({
	providedIn: 'root',
})
export class UserApiService {

	private readonly apiUrls = inject(AppUrlsConfig);

	private readonly httpClient = inject(HttpClient);

	private readonly appErrorMapper = inject(AppErrorMapper);

	private readonly userMapper = inject(UserMapper);

	private readonly userFilterParamsMapper = inject(UserFilterParamsMapper);

	private readonly userStatusParamsMapper = inject(UserStatusFilterParamsMapper);

	private readonly userJobsFilterParamsMapper = inject(UserJobsFilterParamsMapper);

	private readonly paginationMapper = inject(PaginationMapper);

	private readonly userDetailsMapper = inject(UserDetailsMapper);

	private readonly quoteJobMapper = inject(QuoteJobMapper);

	private readonly userJobExtraMetaDataMapper = inject(UserJobExtraMetaDataMapper);

	/** Returns current user info.*/
	public getCurrentUser(): Observable<User> {
		return this.httpClient.get<unknown>(
			this.apiUrls.user.currentProfile,
		)
			.pipe(
				map(response => safeParse(userDtoSchema, response)),
				map(userDto => this.userMapper.fromDto(userDto)),
			);
	}

	/**
	 * Gets users.
	 * @param filters Users filters.
	 */
	public getUsers(filters: UsersFilterParams): Observable<Pagination<User>> {
		const filtersDto = this.userFilterParamsMapper.toDto(filters);
		const params = composeHttpParams(filtersDto);
		return this.httpClient.get<unknown>(this.apiUrls.user.list, { params }).pipe(
			map(response => safeParse(createPaginationDtoSchema(userDtoSchema), response)),
			map(pagination =>
				this.paginationMapper.fromDto(pagination, itemDto => this.userMapper.fromDto(itemDto))),
		);
	}

	/**
	 * Creates user.
	 * @param userCreate User to create.
	 */
	public createUser(userCreate: UserCreate): Observable<UserDetails> {
		const userCreateDto = this.userMapper.toCreateDto(userCreate);
		return this.httpClient.post<unknown>(this.apiUrls.user.create, userCreateDto).pipe(
			map(response => safeParse(userDetailsSchemaDto, response)),
			map(userDto => this.userDetailsMapper.fromDto(userDto)),
			this.appErrorMapper.catchHttpErrorToAppErrorWithValidationSupport(
				this.userDetailsMapper,
			),
		);
	}

	/**
	 * Updates user.
	 * @param id User ID.
	 * @param userCreate User to create.
	 */
	public editUser(id: User['id'], userCreate: UserCreate): Observable<UserDetails> {
		const userCreateDto = this.userMapper.toCreateDto(userCreate);
		return this.httpClient.put<unknown>(this.apiUrls.user.update(id), userCreateDto).pipe(
			map(response => safeParse(userDetailsSchemaDto, response)),
			map(userDto => this.userDetailsMapper.fromDto(userDto)),
		);
	}

	/**
	 * Deletes user by ID.
	 * @param id User ID.
	 */
	public deleteUser(id: User['id']): Observable<void> {
		return this.httpClient.delete<void>(this.apiUrls.user.delete(id));
	}

	/**
	 * Changes user status.
	 * @param id User ID.
	 * @param filter Filter params.
	 */
	public changeUserStatus(id: User['id'], filter: UserStatusFilterParams): Observable<UserDetails> {
		const filtersDto = this.userStatusParamsMapper.toDto(filter);
		const params = composeHttpParams(filtersDto);

		return this.httpClient.put<unknown>(this.apiUrls.user.changeStatus(id), null, { params }).pipe(
			map(response => safeParse(userDetailsSchemaDto, response)),
			map(userDto => this.userDetailsMapper.fromDto(userDto)),
		);
	}

	/**
	 * Gets user by ID.
	 * @param id User ID.
	 */
	public getUserById(id: User['id']): Observable<UserDetails> {
		return this.httpClient.get<unknown>(this.apiUrls.user.detail(id)).pipe(
			map(response => safeParse(userDetailsSchemaDto, response)),
			map(userDetailsDto => this.userDetailsMapper.fromDto(userDetailsDto)),
		);
	}

	/**
	 * Get user jobs.
	 * @param id User ID.
	 * @param filter Filter params.
	 */
	public getUserJobs(id: User['id'], filter: UserJobsFilterParams): Observable<Pagination<QuoteJob, UserJobExtraMetaData>> {
		const filtersDto = this.userJobsFilterParamsMapper.toDto(filter);
		const params = composeHttpParams(filtersDto);
		return this.httpClient.get<unknown>(this.apiUrls.user.jobs(id), { params }).pipe(
			map(response => safeParse(
				createPaginationDtoWithExtraMetaDataSchema(
					quoteJobDtoSchema,
					userJobExtraMetaDataDtoSchema,
				),
				response,
			)),
			map(pagination => this.paginationMapper.fromDto(
				pagination,
				jobDto => this.quoteJobMapper.fromDto(jobDto),
				extraData => this.userJobExtraMetaDataMapper.fromDto(extraData),
			)),
		);
	}
}
